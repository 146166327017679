export interface AssignmentCompensationInterest {
	projectId: string;
	date: number;
	preferentialAmount: string;
	compensableAmount: string;
}

export class AssignmentCompensationInterestHelper {
	static getEmpty(): AssignmentCompensationInterest {
		return {
			projectId: "",
			date: 0,
			preferentialAmount: "",
			compensableAmount: ""
		};
	}
}
import { render, staticRenderFns } from "./LoanAssignmentCompensationTable.vue?vue&type=template&id=a6a26b2e&scoped=true"
import script from "./LoanAssignmentCompensationTable.vue?vue&type=script&lang=js"
export * from "./LoanAssignmentCompensationTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6a26b2e",
  null
  
)

export default component.exports
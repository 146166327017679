<template>
	<loan-content-layout>
		<v-row>
			<v-col class="d-flex align-center justify-end pb-0">
				<loan-switch :value="mode" @update:value="setMode($event)" :options="modeOptions"></loan-switch>
			</v-col>
		</v-row>

		<v-row>
			<v-col class="d-flex flex-column pt-4" style="gap: 24px">
				<!--				<v-card elevation="0" class="loan-card pa-7" style="box-shadow: 3px 6px 20px rgba(0, 0, 0, 0.15) !important">-->
				<!--					<v-row class="align-center">-->
				<!--						<v-col class="d-flex align-center" style="gap: 16px">-->
				<!--							<frp-text-field :placeholder="$t('fields.projectNumber.placeholder')"-->
				<!--											v-model="internalFilterValues.projectNumber"-->
				<!--											hide-details>-->
				<!--							</frp-text-field>-->
				<!--							<frp-text-field :placeholder="$t('fields.organization.label')"-->
				<!--											v-model="internalFilterValues.organization"-->
				<!--											hide-details>-->
				<!--							</frp-text-field>-->
				<!--							<frp-autocomplete :items="agreementStatuses.map(x => ({ ...x, id: x.id.toString() }))"-->
				<!--											  item-text="name"-->
				<!--											  item-value="id"-->
				<!--											  color="blue"-->
				<!--											  v-model="internalFilterValues.statuses"-->
				<!--											  multiple-->
				<!--											  :disabled="isDictionariesLoading"-->
				<!--											  hide-details-->
				<!--											  :placeholder="$t('fields.status.label')">-->
				<!--							</frp-autocomplete>-->
				<!--							<frp-autocomplete :items="[]"-->
				<!--											  item-text="periodName"-->
				<!--											  item-value="id"-->
				<!--											  color="blue"-->
				<!--											  v-model="internalFilterValues.controller"-->
				<!--											  :disabled="isDictionariesLoading"-->
				<!--											  style="width: 250px"-->
				<!--											  hide-details-->
				<!--											  :placeholder="$t('fields.controller.placeholder')">-->
				<!--							</frp-autocomplete>-->
				<!--							<div class="d-flex flex-nowrap ml-12">-->
				<!--								<frp-btn color="primary"-->
				<!--										 @click="resetFilter"-->
				<!--										 :disabled="isFilterEmpty && !isFilterChanged"-->
				<!--										 outlined>-->
				<!--									{{ $t("buttons.reset") }}-->
				<!--								</frp-btn>-->
				<!--								<frp-btn elevation="0"-->
				<!--										 color="blue"-->
				<!--										 dark-->
				<!--										 :disabled="!isFilterChanged"-->
				<!--										 @click="applyFilter">-->
				<!--									{{ $t("buttons.accept") }}-->
				<!--								</frp-btn>-->
				<!--							</div>-->
				<!--						</v-col>-->
				<!--					</v-row>-->
				<!--				</v-card>-->

				<v-card elevation="0" class="loan-card pa-0 pt-6" style="box-shadow: 3px 6px 20px rgba(0, 0, 0, 0.15) !important">
					<v-row>
						<v-col>
							<div class="mx-7 font-weight-bold"
								 :class="currentItems.length || isItemsLoading ? 'mb-4' : 'mb-6'"
								 style="min-height: 24px">
								<span v-if="currentItems.length">
									{{
										mode === AgreementsOverduePaymentsModeType.MAIN_DEBT ?
											$t("content.needAcrrueMainDebtOverdue") : $t("content.needAcrrueInterestOverdue")
									}}
								</span>
								<span v-else-if="!isItemsLoading">{{ $t("content.noOverduePayments") }}</span>
							</div>
							<v-data-table v-if="currentItems.length || isItemsLoading"
										  :headers="headers"
										  :loading-text="$t('tables.loading')"
										  :loading="isItemsLoading"
										  hide-default-footer
										  item-key="id"
										  show-select
										  @toggle-select-all="handleSelectAll"
										  @item-selected="handleSelectItem"
										  :items="currentItems"
										  :items-per-page="-1"
										  :page="paging.page"
										  :mobile-breakpoint="mobileBreakpoint"
										  :options="options"
										  :item-class="() => 'text-body-2 cy-table-row'"
										  class="loan-table colored-table loan-accrual-payments-table clickable-rows d-flex flex-column">
								<template #header.data-table-select="{ props, on }">
									<div class="d-flex align-center">
										<v-simple-checkbox v-bind="props" v-on="on"
														   color="blue"
														   class="frp-simple-checkbox blue--text"
														   :value="selectedItems.length === currentItems.length"
														   :indeterminate="!!selectedItems.length && selectedItems.length !== currentItems.length">
										</v-simple-checkbox>
									</div>
								</template>

								<template #item.data-table-select="{ item }">
									<v-simple-checkbox color="blue"
													   @click="handleSelectItem(item)"
													   :value="checkIsItemSelected(item)">
									</v-simple-checkbox>
								</template>

								<template #item.organizationName="{ item }">
									<frp-text-btn v-if="mode === AgreementsOverduePaymentsModeType.MAIN_DEBT"
												  class="pa-0 loan-text-btn"
												  @click="openLoanSchedule(item.projectId)"
												  :text="item.organizationName"
												  color="blue">
									</frp-text-btn>
									<span v-else style="padding: 1px">{{ item.organizationName }}</span>
								</template>

								<template #item.paymentDate="{ item }">
									<span>{{ formatDate(item.paymentDate, dateFormat) }}</span>
								</template>

								<template #item.paymentAmount="{ item }">
									<span>{{ formatCurrency(item.paymentAmount) }}</span>
								</template>

								<template #item.overAmount="{ item }">
									<span>{{ formatCurrency(item.overAmount) }}</span>
								</template>

								<template #item.link="{ item }">
									<frp-btn without-padding
											 @click="openLink(item)"
											 icon
											 height="20"
											 small
											 color="blue"
											 dark>
										<frp-icon :color="colors.primary.base"
												  height="20"
												  width="20"
												  src="ico_chart">
										</frp-icon>
									</frp-btn>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
				</v-card>

				<frp-bottom-space height="114"></frp-bottom-space>

				<loan-actions>
					<frp-btn elevation="0"
							 color="blue"
							 class="mr-n3"
							 @click="openLastUnfinishedOverdueOrder"
							 :disabled="overdueOrderProcessing"
							 :loading="openOverdueOrderProcessing"
							 dark>
						{{ $t("buttons.addOverdueOrder") }}
					</frp-btn>
					<frp-btn elevation="0"
							 color="blue"
							 class="mr-n3"
							 :disabled="!selectedItems.length || !currentItems.length || overdueOrderProcessing"
							 :loading="addOverdueOrderProcessing"
							 @click="addOverdueOrder"
							 dark>
						{{ $t("buttons.accrueOverdue") }}
					</frp-btn>
				</loan-actions>
			</v-col>
		</v-row>

		<frp-overdue-order-dialog :title="mode == AgreementsOverduePaymentsModeType.MAIN_DEBT ? $t('dialogs.addOverdueOrder.mainDebtTitle') : $t('dialogs.addOverdueOrder.interestTitle')"
								  :blob="overdueOrderSource"
								  :submitTitle="$t('dialogs.addOverdueOrder.submitTitle')"
								  @close="closeOverdueDialog"
								  @submit="submitOverdueOrder"></frp-overdue-order-dialog>

	</loan-content-layout>
</template>

<script>
import { OverdueOrderController } from "@/api/loan/overdueOrders";
import AbortService from "@/services/abortService";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import FrpAlert from "Components/alerts/FrpAlert.vue";
import FrpTextBtn from "Components/buttons/FrpTextBtn";
import LoanActions from "Components/buttons/LoanActions";
import FrpFile from "Components/common/FrpFile.vue";
import FrpDetailsItem from "Components/details/FrpDetailsItem.vue";
import FrpDigitalSignatureDialog from "Components/digitalSignature/FrpDigitalSignatureDialog.vue";
import LoanDropzone from "Components/dropzone/LoanDropzone.vue";
import FrpFileField from "Components/fields/FrpFileField.vue";
import FrpTextarea from "Components/fields/FrpTextarea.vue";
import LoanSwitch from "Components/fields/LoanSwitch";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import LoanContentLayout from "Components/layouts/LoanContentLayout";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpPagination from "Components/common/FrpPagination";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import FrpTextField from "Components/fields/FrpTextField";
import LoanBooleanSwitch from "Components/fields/LoanBooleanSwitch";
import FrpIcon from "Components/icon/FrpIcon";
import { assign, isEqual } from "lodash";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import { listMixin } from "Mixins/listMixin";
import { i18n } from "Plugins/index";
import { stringify } from "qs";
import { RouteNames } from "Router/loan/routes";
import AgreementsOverduePaymentsFilter from "Store/loan/modules/agreementsOverduePayments/types/agreementsOverduePaymentsFilter";
import { AgreementsOverduePaymentsModeType } from "Store/loan/modules/agreementsOverduePayments/types/agreementsOverduePaymentsModeType";
import { formatDate, convertToZonedIso } from "Utils/dates";
import { dateFormat } from "Utils/formats";
import { formatCurrency } from "Utils/formatting";
import { namespace, getterTypes, mutationTypes, actionTypes } from "Store/loan/modules/agreementsOverduePayments/types";
import { openRouteInNewTab } from "Utils/router";
import { createNamespacedHelpers } from "vuex";
import FrpOverdueOrderDialog from "Components/dialogs/FrpOverdueOrderDialog";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

const abortService = new AbortService();
const overdueOrderController = new OverdueOrderController(abortService);

export default {
	metaInfo: {
		title: i18n.t("metaTitles.agreementsOverduePayments")
	},
	mixins: [listMixin, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			formatDate,
			formatCurrency,
			RouteNames,
			dateFormat,
			AgreementsOverduePaymentsModeType,
			modeOptions: [
				{
					text: this.$t(`aliases.accrualPaymentsModeType.${AgreementsOverduePaymentsModeType.INTEREST}`),
					value: AgreementsOverduePaymentsModeType.INTEREST
				},
				{
					text: this.$t(`aliases.accrualPaymentsModeType.${AgreementsOverduePaymentsModeType.MAIN_DEBT}`),
					value: AgreementsOverduePaymentsModeType.MAIN_DEBT
				}
			],
			internalFilterValues: {
				projectNumber: "",
				organization: "",
				controller: "",
				statuses: []
			},
			overdueOrder: null,
			overdueOrderSource: null,
			isNewOverdueOrder: false,
			addOverdueOrderProcessing: false,
			openOverdueOrderProcessing: false
		};
	},
	computed: {
		...mapState({
			state: state => state,
			mode: state => state.mode,
			isInitialized: state => state.isInitialized,
			isDictionariesLoading: state => state.isDictionariesLoading,
			agreementStatuses: state => state.agreementStatuses,
			financeSources: state => state.financeSources,
			quarters: state => state.quarters,
			selectedItems: state => state.selectedItems
		}),
		...mapGetters({
			currentItems: getterTypes.currentItems
		}),
		overdueOrderProcessing() {
			return this.addOverdueOrderProcessing || this.openOverdueOrderProcessing;
		},
		headers() {
			return [
				{
					text: this.$t("tables.projectNumber"),
					value: "projectNumber",
					class: "text-caption",
					width: "9%",
					sortable: false
				},
				{
					text: this.$t("tables.organizationName"),
					value: "organizationName",
					class: "text-caption",
					width: "11%",
					sortable: false,
					sort: (a, b) => a.localeCompare(b)
				},
				{
					text: this.$t("tables.status"),
					value: "status",
					class: "text-caption",
					width: "12%",
					sortable: false
				},
				{
					text: this.$t("tables.financeSource"),
					value: "source.name",
					class: "text-caption",
					width: "15%",
					sortable: false
				},
				{
					text: this.$t("tables.quarter"),
					value: "quarter.title",
					class: "text-caption",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.plannedOverduePaymentDate"),
					value: "paymentDate",
					class: "text-caption",
					width: "11%",
					sortable: false
				},
				{
					text: this.$t("tables.plannedPaymentSum"),
					value: "paymentAmount",
					class: "text-caption",
					width: "12%",
					sortable: false
				},
				{
					text: this.$t("tables.overdue"),
					value: "overAmount",
					class: "text-caption",
					width: "12%",
					sortable: false
				},
				{
					text: "",
					value: "link",
					class: "text-caption",
					width: "1%",
					sortable: false
				}
			];
		},
		filter() {
			return {
				projectNumber: this.internalFilterValues.projectNumber,
				organization: this.internalFilterValues.organization,
				controller: this.internalFilterValues.controller,
				statuses: this.internalFilterValues.statuses
			};
		},
		isFilterChanged() {
			return !isEqual(Object.fromEntries(Object.entries(this.internalFilterValues).map(([k, v]) => [k, v || ""])),
				assign({}, this.filterValues));
		},
		isFilterEmpty() {
			return isEqual(new AgreementsOverduePaymentsFilter(), this.filterValues);
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize,
			updateListingItems: actionTypes.updateListingItems
		}),
		...mapMutations({
			setFilterProjectNumber: mutationTypes.SET_FILTER_PROJECT_NUMBER,
			setFilterOrganization: mutationTypes.SET_FILTER_ORGANIZATION,
			setFilterController: mutationTypes.SET_FILTER_CONTROLLER,
			setFilterStatuses: mutationTypes.SET_FILTER_STATUSES,
			setSelectedItems: mutationTypes.SET_SELECTED_ITEMS,
			addSelectedItem: mutationTypes.ADD_SELECTED_ITEM,
			removeSelectedItem: mutationTypes.REMOVE_SELECTED_ITEM,
			setMode: mutationTypes.SET_MODE
		}),
		resetFilter() {
			this.internalFilterValues.projectNumber = "";
			this.internalFilterValues.organization = "";
			this.internalFilterValues.controller = "";
			this.internalFilterValues.statuses = [];
			this.applyFilter();
		},
		setInternalFilterValues() {
			this.internalFilterValues.projectNumber = this.filterValues.projectNumber;
			this.internalFilterValues.organization = this.filterValues.organization;
			this.internalFilterValues.controller = this.filterValues.controller;
			this.internalFilterValues.statuses = this.filterValues.statuses || [];
		},
		applyFilter() {
			this.setFilterProjectNumber(this.internalFilterValues.projectNumber);
			this.setFilterOrganization(this.internalFilterValues.organization);
			this.setFilterController(this.internalFilterValues.controller);
			this.setFilterStatuses(this.internalFilterValues.statuses || []);
		},
		handleSelectAll({ value }) {
			if(value) {
				this.setSelectedItems(this.currentItems);
			} else {
				this.setSelectedItems([]);
			}
		},
		handleSelectItem(item) {
			if(this.checkIsItemSelected(item))
				this.removeSelectedItem(item);
			else
				this.addSelectedItem(item);
		},
		checkIsItemSelected(item) {
			return this.selectedItems.some(x => x.id === item.id);
		},
		async openLoanSchedule(projectId) {
			openRouteInNewTab(this.$router, { name: RouteNames.LOAN_SCHEDULE, params: { projectId } });
		},
		async openLink({ projectId }) {
			const date = formatDate(new Date().getTime(), dateFormat);
			const query = stringify({
				"rs:Command": "Render",
				ProjectId: projectId,
				OnDate: date
			});
			const url = `${process.env.VUE_APP_LOAN_OVERDUE_PAYMENTS_REPORTING_LINK}&${query}`;
			window.open(url, "_blank");
		},
		async addOverdueOrder() {
			this.isNewOverdueOrder = true;
			var items = this.selectedItems.map(x => {
				return { projectId: x.projectId, startDate: convertToZonedIso(x.paymentDate), financingSourceId: x.financingSourceId, quarterId: x.quarterId, amount: x.overAmount }
			});

			this.addOverdueOrderProcessing = true;
			try {
				this.overdueOrder = await overdueOrderController.addOverdueOrder(items, this.mode);
				const pdf = await overdueOrderController.getOverdueOrderPdf(this.overdueOrder.number, this.mode);
				this.overdueOrderSource = pdf;
			} catch (error) {
				AlertHelper.handleGeneralRequestErrors(error);
				console.error(error);
				this.closeOverdueDialog();
			} finally {
				this.addOverdueOrderProcessing = false;
			}
		},
		async openLastUnfinishedOverdueOrder() {
			this.isNewOverdueOrder = false;
			this.openOverdueOrderProcessing = true;
			try {
				this.overdueOrder = await overdueOrderController.getOverdueOrder(0, this.mode);
				if (this.overdueOrder === null) {
					alertService.addInfo(AlertKeys.OVERDUE_ORDER_NOT_FOUND);
					this.closeOverdueDialog();
					return;
				}
				const pdf = await overdueOrderController.getOverdueOrderPdf(this.overdueOrder.number, this.mode);
				this.overdueOrderSource = pdf;
			} catch (error) {
				AlertHelper.handleGeneralRequestErrors(error);
				console.error(error);
			} finally {
				this.openOverdueOrderProcessing = false;
			}
		},
		closeOverdueDialog() {
			this.overdueOrder = null;
			this.overdueOrderSource = null;
			if (this.isNewOverdueOrder) {
				this.setSelectedItems([]);
				this.updateListingItems();
			}
		},
		async submitOverdueOrder(comment) {
			try {
				await overdueOrderController.submitOverdueOrder(this.overdueOrder.id, comment, this.mode);
				this.closeOverdueDialog();
				alertService.addInfo(AlertKeys.SUCCESS_SENT_INFO);
			} catch (error) {
				AlertHelper.handleGeneralRequestErrors(error);
				console.error(error);
			}
		}
	},
	created() {
		abortService.initialize();
		this.initializeStore();
	},
	components: {
		LoanActions,
		FrpTextBtn,
		FrpBottomSpace,
		LoanSwitch,
		FrpAlert,
		FrpDetailsItem,
		FrpTextarea,
		FrpDigitalSignatureDialog,
		FrpFileField,
		FrpFile,
		LoanDropzone,
		LoanContentLayout,
		FrpPagination,
		FrpIcon,
		FrpAutocomplete,
		FrpBtn,
		LoanBooleanSwitch,
		FrpTextField,
		FrpOverdueOrderDialog
	}
};
</script>
<style lang="scss" scoped>
.v-input--selection-controls__ripple {
	width: 27px;
	height: 27px;
	left: -8px;
	top: calc(50% - 20px);
}
</style>

<template>
	<v-row>
		<v-col>
			<v-data-table :headers="headers"
						  :loading-text="$t('tables.loading')"
						  :loading="isItemsLoading"
						  hide-default-footer
						  item-key="id"
						  :items="items"
						  :items-per-page="-1"
						  :mobile-breakpoint="mobileBreakpoint"
						  :options="options"
						  :item-class="() => 'text-body-2 cy-table-row'"
						  class="loan-table colored-table troubled-borrowers-table clickable-rows d-flex flex-column">
				<template #item.assignmentAgreement="{item}">
					<span>
						{{
							`${item.assignmentAgreementNumber} ${$t("common.from")} ${formatDate(item.assignmentAgreementDate, dateFormat)}`
						}}
					</span>
				</template>
				
				<template #item.preferentialAmount="{item: { preferentialAmount }}">
					{{ formatCurrency(+preferentialAmount) }}
				</template>
				
				<template #item.compensableAmount="{item: { compensableAmount }}">
					{{ formatCurrency(+compensableAmount) }}
				</template>
				
				<template #item.compensationAmount="{item: { compensationAmount }}">
					{{ formatCurrency(+compensationAmount) }}
				</template>
				
				<template #item.accrualType="{item: { accrualType }}">
					{{ $t(`aliases.accrualTypes.${accrualType}`) }}
				</template>
				
				<template #item.action="{item}">
					<frp-btn class="text-none pl-1 pr-2"
							 outlined
							 :disabled="isAssignmentCompensationLoading"
							 :color="colors.blue.base"
							 @click="handleAddInterest(item)">
						<v-icon :color="colors.blue.base" class="mr-2">mdi-plus</v-icon>
						{{ $t("buttons.addNote") }}
					</frp-btn>
				</template>
				
				<template #foot>
					<tfoot class="text-subtitle-1 font-weight-medium grey--text text--darken-3">
					<tr>
						<template>
							<td></td>
							<td></td>
							<td>
								<span>{{ $t("content.total") }}</span>
							</td>
							<td>
								<span>
									{{ formatCurrency(items.map(x => x.preferentialAmount).reduce((x, sum) => sum += +x, 0)) }}
								</span>
							</td>
							<td>
								<span>
									{{ formatCurrency(items.map(x => x.compensableAmount).reduce((x, sum) => sum += +x, 0)) }}
								</span>
							</td>
							<td>
								<span>
									{{ formatCurrency(items.map(x => x.compensationAmount).reduce((x, sum) => sum += +x, 0)) }}
								</span>
							</td>
						</template>
					</tr>
					</tfoot>
				</template>
			
			</v-data-table>
		</v-col>
		
		<frp-dialog v-model="isAddInterestDialogOpened" :title="$t('dialogs.addingPercentagesManually.title')" persistent>
			<template #content>
				<v-form :ref="refs.form" v-model="formValid">
					<frp-autocomplete :label="$t('fields.project.label')"
									  v-model="newInterestItemProjectId"
									  :items="formattedAssignmentCompensationProjects"
									  disabled
									  color="blue"
									  item-value="id"
									  item-text="name"
									  required>
					</frp-autocomplete>
					
					<frp-date-field :label="$t('fields.calculatingDate.label')"
									v-model="newInterestItemDate"
									required
									:disabled="isFormSaving"
									:placeholder="$t('fields.calculatingDate.placeholder')">
					</frp-date-field>
					
					<frp-text-field :label="$t('fields.preferentialAmount.label')"
									v-model="newInterestItemPreferentialAmount"
									:rules="validation.preferentialAmount"
									required
									:disabled="isFormSaving"
									:placeholder="$t('fields.preferentialAmount.placeholder')">
					</frp-text-field>
					
					<frp-text-field :label="$t('fields.compensableAmount.label')"
									v-model="newInterestItemCompensableAmount"
									:rules="validation.compensableAmount"
									required
									:disabled="isFormSaving"
									:placeholder="$t('fields.compensableAmount.placeholder')">
					</frp-text-field>
				</v-form>
			</template>
			
			<template #footer>
				<frp-btn outlined
						 :disabled="isFormSaving"
						 @click="onFormCancel"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn class="elevation-0"
						 color="blue"
						 :loading="isFormSaving"
						 :disabled="!formValid"
						 dark
						 @click="handleSaveAddInterest">
					{{ $t("buttons.save") }}
				</frp-btn>
			</template>
		</frp-dialog>
		
		<loan-alert-dialog v-if="isChangesSavedDialogOpened"
						   close-btn-dark
						   :title="$t('dialogs.changesSaved.title')"
						   @dialog:close="isChangesSavedDialogOpened = false">
		</loan-alert-dialog>
	</v-row>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import LoanAlertDialog from "@/components/dialogs/LoanAlertDialog.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import colorsMixin from "@/mixins/colorsMixin";
import formMixin from "@/mixins/formMixin";
import { listMixin } from "@/mixins/listMixin";
import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/loan/modules/assignmentCompensation/types";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { formatCurrency } from "@/utils/formatting";
import { onlyNumbersRule } from "@/utils/validation";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [listMixin, colorsMixin, formMixin],
	props: {
		isAssignmentCompensationLoading: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			namespace,
			formatDate,
			dateFormat,
			formatCurrency,
			isAddInterestDialogOpened: false,
			isChangesSavedDialogOpened: false,
			validation: {
				preferentialAmount: [onlyNumbersRule()],
				compensableAmount: [onlyNumbersRule()]
			}
		};
	},
	computed: {
		...mapGetters({
			formattedAssignmentCompensationProjects: getterTypes.formattedAssignmentCompensationProjects
		}),
		...mapState({
			state: state => state,
			newInterestItem: state => state.newInterestItem,
			isAssignmentCompensationCalculating: state => state.isAssignmentCompensationCalculating
		}),
		headers() {
			return [
				{
					text: this.$t("tables.projectNumber"),
					value: "applicationNumber",
					class: "text-caption",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.organizationName"),
					value: "organizationName",
					class: "text-caption",
					width: "20%",
					sortable: false
				},
				{
					text: this.$t("tables.assignmentAgreement"),
					value: "assignmentAgreement",
					class: "text-caption",
					width: "12%",
					sortable: false
				},
				{
					text: this.$t("tables.preferentialAmount"),
					value: "preferentialAmount",
					class: "text-caption",
					width: "12%",
					sortable: false
				},
				{
					text: this.$t("tables.compensableAmount"),
					value: "compensableAmount",
					class: "text-caption",
					width: "12%",
					sortable: false
				},
				{
					text: this.$t("tables.compensationAmount"),
					value: "compensationAmount",
					class: "text-caption",
					width: "12%",
					sortable: false
				},
				{
					text: this.$t("tables.daysByPeriod"),
					value: "periodDays",
					class: "text-caption",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.accrualType"),
					value: "accrualType",
					class: "text-caption",
					width: "10%",
					sortable: false
				},
				{
					text: "",
					value: "action",
					class: "text-caption",
					width: "10%",
					sortable: false
				}
			];
		},
		newInterestItemProjectId: {
			get() {
				return this.newInterestItem.projectId;
			},
			set(value) {
				this.setNewInterestItemProjectId(value);
			}
		},
		newInterestItemDate: {
			get() {
				return this.newInterestItem.date;
			},
			set(value) {
				this.setNewInterestItemDate(value);
			}
		},
		newInterestItemPreferentialAmount: {
			get() {
				return this.newInterestItem.preferentialAmount;
			},
			set(value) {
				this.setNewInterestItemPreferentialAmount(value);
			}
		},
		newInterestItemCompensableAmount: {
			get() {
				return this.newInterestItem.compensableAmount;
			},
			set(value) {
				this.setNewInterestItemCompensableAmount(value);
			}
		}
	},
	methods: {
		...mapActions({
			addInterest: actionTypes.addInterest
		}),
		...mapMutations({
			resetNewInterestItem: mutationTypes.RESET_NEW_INTEREST_ITEM,
			setNewInterestItemProjectId: mutationTypes.SET_NEW_INTEREST_ITEM_PROJECT_ID,
			setNewInterestItemDate: mutationTypes.SET_NEW_INTEREST_ITEM_DATE,
			setNewInterestItemPreferentialAmount: mutationTypes.SET_NEW_INTEREST_ITEM_PREFERENTIAL_AMOUNT,
			setNewInterestItemCompensableAmount: mutationTypes.SET_NEW_INTEREST_ITEM_COMPENSABLE_AMOUNT
		}),
		handleAddInterest(item) {
			if(!item) return;
			
			const { id } = item;
			if(!id) return;
			
			this.setNewInterestItemProjectId(id.toString());
			
			this.isAddInterestDialogOpened = true;
		},
		cancelChanges() {
			this.isAddInterestDialogOpened = false;
			this.resetNewInterestItem();
		},
		async handleSaveAddInterest() {
			await this.addInterest();
			
			await this.onFormCancel();
			
			this.isChangesSavedDialogOpened = true;
		},
		setInternalFilterValues() {
		}
	},
	components: {
		FrpTextField,
		FrpDateField,
		FrpAutocomplete, FrpDialog,
		LoanAlertDialog,
		FrpBtn,
		FrpIcon
	}
};
</script>

<style scoped>
</style>

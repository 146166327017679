import { ApiAssignmentCompensationProject } from "@/api/loan/types/assignmentCompensation/apiAssignmentCompensationProject";
import { convertToZonedTimestamp } from "@/utils/dates";

export interface AssignmentCompensationProject {
	id: number;
	loanApplicationNumber: number;
	organizationName: string;
	assignmentAgreementId: string;
	assignmentAgreementNumber: string;
	assignmentAgreementDate: number;
	preferentialAmount: number;
	compensableAmount: number;
	compensationAmount: number;
	periodDays: number;
	from: number;
	to: number;
	accrualType: string;
}

export class AssignmentCompensationProjectMapper {
	static map(source: ApiAssignmentCompensationProject): AssignmentCompensationProject {
		return {
			...source,
			assignmentAgreementId: source.assignmentAgreementId.toString(),
			assignmentAgreementDate: convertToZonedTimestamp(source.assignmentAgreementDate) as number,
			from: convertToZonedTimestamp(source.from) as number,
			to: convertToZonedTimestamp(source.to) as number
		};
	}
}
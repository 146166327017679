import { AssignmentCompensationInterest } from "@/store/loan/modules/assignmentCompensation/types/assignmentCompensationInterest";
import { convertToZonedIso } from "@/utils/dates";

export interface ApiAssignmentCompensationInterestRequest {
	date: string;
	preferentialAmount: number;
	compensableAmount: number;
}

export class ApiAssignmentCompensationInterestRequestMapper {
	static map(source: AssignmentCompensationInterest): ApiAssignmentCompensationInterestRequest {
		return {
			date: convertToZonedIso(source.date),
			preferentialAmount: +source.preferentialAmount,
			compensableAmount: +source.compensableAmount
		};
	}
}